@use '@angular/material' as mat;


// Define the palettes for your theme
$webadmin-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$webadmin-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);
$webadmin-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Define light and dark themes
$webadmin-theme: mat.m2-define-light-theme((
  color: (
    primary: $webadmin-primary,
    accent: $webadmin-accent,
    warn: $webadmin-warn,
  )
));

$webadmin-dark-theme: mat.m2-define-dark-theme((
  color: (
    primary: $webadmin-primary,
    accent: $webadmin-accent,
    warn: $webadmin-warn,
  )
));

@include mat.core-theme($webadmin-theme);
@include mat.button-theme($webadmin-theme);
@include mat.all-component-colors($webadmin-theme);

.dark-theme {
  @include mat.core-color($webadmin-dark-theme);
  @include mat.all-component-colors($webadmin-dark-theme);
}

// Global styles
html, body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

// Remove focus outlines from buttons
button:focus,
button:active:focus,
button.active:focus {
  outline: none;
}

// Button and form field margin adjustments
.content-button-margin > button {
  margin-right: 8px;
}
.content-button-margin > button:last-child {
  margin-right: 0px;
}

.content-formfield-margin mat-form-field {
  margin-right: 8px;
}
.content-formfield-margin mat-form-field:last-child {
  margin-right: 0px;
}

// Grid tile styling
mat-grid-tile {
  div.mat-grid-tile-content {
    display: block;
    margin: 0 2rem;

    mat-form-field {
      display: block;
    }
  }
}

// Table container adjustments
.pagedtable-container {
  position: relative;
  min-height: 200px;
}

.pagedtable-container .table-container {
  position: relative;
  overflow: auto;
}

.pagedtable-container .loading-shade {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 56px;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  z-index: 1;
  display: flex;
  justify-content: center;

  mat-spinner {
    margin-top: 2rem;
  }
}

// Breadcrumb styling
nav > ol.breadcrumb {
  background-color: unset;
  margin-bottom: 0px;
  padding-left: 0px;
}

// Disable touch targets
.mat-mdc-button-touch-target {
  display: none;
}
